import React from 'react'
import { array, func, node, object, oneOfType, shape, string } from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import TeamRoll from '../components/TeamRoll'

export const BlogPostTemplate = ({ title, content, contentComponent, description, featuredImage, helmet }) => {
  const PageContent = contentComponent || Content
  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <div>
      {helmet || ''}
      <section className="pt5 pt6-l bg-black cover" style={{ backgroundImage: 'url(/img/prole-bg.jpg)'}}>
        <div className="tc pv4 pv5-ns ph4">
          <h1 className="mt0 mb2 f4 f3-ns yellow ttu">
            {title}
          </h1>
        </div>
      </section>
      <section>
        <div className="mw7-ns center pa2 pa3-m pa4-l flex flex-wrap">
          <div className="w-100 mb3 flex flex-wrap">
            <div className="w-100 w-40-ns pr4">
              <div className="flex items-center">
                <div className="w3 pr3">
                  <div className="aspect-ratio aspect-ratio--1x1">
                    <div
                      className="aspect-ratio--object bg-light-gray br-pill"
                      style={{
                        backgroundImage: 'url(/img/oswaldo-dumas.png)',
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  </div>
                </div>
                <div className="f6">
                  Por Oswaldo Dumas
                </div>
              </div>
            </div>
            <div className="w-100 w-60-ns flex justify-end items-center">
              <span>Compartilhe este post:</span>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                className="ml3 f3 gray"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook" aria-hidden="true" />
              </a>
              <a
                href={`http://twitter.com/share?text=${title}&url=${url}&hashtags=prolesports`}
                className="ml3 f3 gray"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" aria-hidden="true" />
              </a>
            </div>
          </div>          
          <div className="w-100">
            {featuredImage ? (
              <Img
                className="mb3 mb4-ns nl5-l nr5-l"
                fluid={featuredImage.childImageSharp.fluid}
                alt={`${{title}} Illustration`}
              />
            ) : null}
            <PageContent className="content gray lh-copy f4-ns" content={content} />
          </div>
        </div>
      </section>
      <section className="bg-near-white">
        <div className="mw8-ns center pa2 pa3-m pa4-l">
          <h2 className="ttu pl3 mb2 mt2">Conheça o Time Prole Sports</h2>
          <TeamRoll />
        </div>
      </section>      
    </div>
  )
}

BlogPostTemplate.propTypes = {
  image: oneOfType([object, string]),
  title: string,
  heading: string,
  subheading: string,
  mainpitch: object,
  description: string,
  intro: shape({
    blurbs: array,
  }),
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.html}
        featuredImage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  content: node.isRequired,
  contentComponent: func,
  description: string,
  featuredimage: string,
  helmet: object,
  title: string,
}

export default BlogPost

export const blogPostQuery = graphql`
query BlogPostByID($id: String!) {
  markdownRemark(id: { eq: $id }) {
    id
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      description
      featuredimage {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tags
      title
    }
  }
}
`
