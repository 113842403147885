import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

class TeamRoll extends Component {
  render() {
    const { data } = this.props
    const { edges: pros } = data.allMarkdownRemark
    return (
      <ul className="list ma0 flex flex-wrap">
        {pros &&
          pros.map(({ node: pro }) => (
            <li key={pro.fields.slug} className="pa2 pa3-ns w-50 w-25-ns">
              <a href={pro.fields.slug} data-id="person-pod">
                <figure className="relative">
                  <img alt={pro.frontmatter.alias} src={`/img/${pro.frontmatter.image.relativePath}`} className="br3" />
                  <figcaption className="absolute bottom-0 left-0 right-0 tc white pa3 top-0 flex justify-end flex-column">
                    <div>
                      <div className="mb1">{pro.frontmatter.alias}</div>
                      <div className="db ttu f7"><small>{pro.frontmatter.roles.join(', ')}</small></div>
                    </div>
                  </figcaption>
                </figure>
              </a>
            </li>
          ))
        }
      </ul>
    )
  }
}

TeamRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query TeamRollQuery {
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___alias]}, filter: {frontmatter: {templateKey: {eq: "member-page"}}}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                alias
                roles
                image {
                  relativePath
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <TeamRoll data={data} count={count} />}
  />
)
